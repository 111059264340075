import React from 'react'
import MainContainer from '../MainContainer/MainContainer'
import MainContent from '../MainContent/MainContent'
const NewsPage = () => {
  return (
    <MainContainer>
      <MainContent
        rightSideVisible={false}
        header={"COJON NEWS"}
        description={"Find projects status, and whats being said"}
        //links={['Awards', 'Saftey Standards', 'Testimonial', 'Empoyee Portal', 'Birthdays', 'News Letters']}
      />
    </MainContainer>
  )
}

export default NewsPage