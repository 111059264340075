import React from 'react'
import backgroundImage from '../../images/header.jpg';
import { useState, useEffect } from 'react';
const SmallHeader = () => {

  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <div className="flex h-full">
      <div
        className="w-full"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: width <= 869 ? `${width-600}px` : '250px',
          minHeight:'150px'
        
        }}
      >
        {/* Your content here */}
      </div>
    </div>
  );
}

export default SmallHeader