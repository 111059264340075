import React from 'react';

const ImageGallery = ({ images, openModal }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1 p-8 px-4 bg-zinc-800 ">
      {images.map((image, index) => (
        <div onClick={() => openModal(image.projectName)} key={index} className="flex flex-col justify-center hover:cursor-pointer">
          <img src={image.backgroundImage} alt={`Image ${index}`} className="rounded object-fill" />
          <div className='py-4 bg-zinc-300 rounded-b text-xl'>
            <p className="text-zinc-800 font-medium py-6">{image.projectName}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;
