import React from 'react'
import SmallHeader from '../SmallHeader/SmallHeader'


const MainContent = ({header, rightHeader, description, message, submessage, links, rightSideVisible, children, selector}) => {

  const styleString = rightSideVisible == true ? 'border-r border-zinc-500' : '';
  return (
    <div className="">

      {/* <SmallHeader /> */}
      <SmallHeader />
      <div className='border flex flex-row min-h-[500px]'>
        <div className={`hidden sm:block w-2/3 pt-12 pr-12 ${styleString}`}>
          <div className="flex flex-col pl-12">
            <h1 className="text-2xl text-left pb-2 font-semibold text-sky-800">{header}</h1>
            <p className="text-lg text-left text-sky-800">{description}</p>
            {submessage && <span className="text-md text-left text-sky-800">{submessage}</span> }
          </div>
          <div className="mt-8 text-left pl-12">
            <div className="text-left bg-zinc-500 bg-opacity-30 mb-8 p-1 rounded">
              {children}
            </div>

           
          </div>
        </div>
        {rightSideVisible &&
          <div className="w-full sm:w-1/3 p-8 flex flex-col text-left justify-start pl-6">
            
            <h2 className="text-2xl font-semibold text-sky-800">
              {rightHeader && rightHeader}
            </h2>
            
            <div className="flex flex-col w-fit">
              {links && links.map((link) => {
                return (
                  <span className="text-sky-800 py-2 hover:cursor-pointer"
                    onClick={selector}
                  >{link}</span>
                )
              })}
      
              {/* <span className="text-sky-800 py-2 hover:cursor-pointer">what is it</span>
              <span className="text-sky-800 py-2 hover:cursor-pointer">what is it</span>
              <span className="text-sky-800 py-2 hover:cursor-pointer">what is it</span>
              <span className="text-sky-800 py-2 hover:cursor-pointer">what is it</span> */}
              
            </div>
          </div>
        }
         
      </div>
     
     

    </div>
  )
}

export default MainContent