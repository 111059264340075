import React from 'react'
import MainContainer from '../MainContainer/MainContainer'
import MainContent from '../MainContent/MainContent'
import Representation from '../Representation/Representation'

const PeoplePage = () => {
  return (
    <MainContainer>
      <MainContent
        rightSideVisible={false}
        message={"This is some text about how great the company is and what they are really about"}
        header={"OUR PERSONNEL"}
        // links={['Awards', 'Saftey Standards', 'Testimonial', 'Empoyee Portal', 'Birthdays', 'News Letters']}
      />

     <Representation />

    </MainContainer>
  )
}

export default PeoplePage