import React from 'react'
import { Link } from 'react-router-dom';
import SocialNav from '../SocialNav/SocialNav';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
// import { toggleMenu } from '../../slices/GlobalState/globalStateSlice';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/cojon.jpg'


const Nav = ({setOpen}) => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  return (
    <div className="fixed top-0 z-10 bg-white w-full">
      <SocialNav />
      <div className="h-24 w-full flex relative justify-between">
        <div className="w-[200px] min-w-[200px] h-24 flex items-center pl-4">
          <img
          
            alt="cojon.jpg"
            src={logo}
          />
        </div>
        <ul className="invisible md:visible md:w-1/2 text-lg mr-4 flex justify-around items-center flex-end text-lg">
          <Link className="hover:text-sky-800 font-normal" to="/">HOME</Link>
          <Link className="hover:text-sky-800 font-normal" to="/about">ABOUT</Link>
          {/* <Link className="hover:text-sky-800 font-normal" to="/people">PEOPLE</Link> */}
          <Link className="hover:text-sky-800 font-normal" to="/services">SERVICES</Link>
          <Link className="hover:text-sky-800 font-normal" to="/projects">PROJECTS</Link>
          {/* <Link className="hover:text-sky-800 font-normal" to="/news">NEWS</Link>
          <Link className="hover:text-sky-800 font-normal" to="/careers">CAREERS</Link> */}
          <Link className="hover:text-sky-800 font-normal" to="/contact">CONTACT</Link>
        </ul>
        <div className="md:hidden absolute right-3 top-3 flex justify-around items-center hover:cursor-pointer"
          onClick={() => {setOpen((prev) => !prev)}}
        >
          <FontAwesomeIcon icon={faBars} className='text-xl' />
        </div>   
      </div> 
   
    </div>
   
  )
}

export default Nav