import React from 'react'
import MainContainer from '../MainContainer/MainContainer'
import MainContent from '../MainContent/MainContent'
const CareersPage = () => {
  return (
    <MainContainer>
      <MainContent
        header={`WE'RE HIRING!`}
        description={'Come join us to help build communities for a better tomorrow'}
        rightSideVisible={false}
      >
        <div>
          <h2 className='text-2xl text-sky-800'>POSITIONS</h2>
          <ul className='py-4 w-fit'>
            <li className="py-2 text-sky-800 hover:cursor-pointer">
              POSITION 1
            </li>
            <li className="py-2 text-sky-800 hover:cursor-pointer">
              POSITION 2
            </li>
            <li className="py-2 text-sky-800 hover:cursor-pointer">
              POSITION 3
            </li>

          </ul>
        </div>
      </MainContent>
    </MainContainer>
  )
}

export default CareersPage