import React from 'react'

const Moto = ({statement}) => {
  return (
    <div className="w-full bg-neutral-700 flex justify-content items-center py-16">
      <div className="w-4/5 mx-auto flex flex-col justify-content my-2">
        <h3 className="text-3xl pb-8 text-white">If It's Worth Doing, It's Worth Doing Right</h3>
        <p className="leading-8 text-white font-light pb-8">
          {statement}
        </p>
        <p className="leading-8 text-white font-light">
          At Cojon we take on the challenging projects and complete them safely, on time, on budget and with the highest quality possible.
        </p>
        <br/>
        <p className="leading-8 text-white font-light">
          We take pride in our work and stand out from the competition by putting in the time and effort to communicate and plan our projects with our customers. 
        </p>
      </div>
   
    </div>
  )
}

export default Moto