import React from 'react'
import MainContainer from '../MainContainer/MainContainer'
import MainContent from '../MainContent/MainContent'
import LocationBlock from '../LocationBlock/LocationBlock'
import SmallHeader from '../SmallHeader/SmallHeader'
import SEO from '../SEO/SEO';

const ContactPage = () => {
  return (
    <MainContainer>
      <SEO 
        title="COJON: Mechanical Engineering & Construction"
        description="Portland Metro & Southwest Washington Commercial & Industrial Contracting"
        name="COJON"
        type="website"
        index
      />
      <SmallHeader />
      <div className='flex flex-row min-h-[500px]'>
        <div className={`w-full pt-8 md:pl-12 md:pr-12`}>
          <div className="flex flex-col">
            <h1 className="pl-4 md:pl-0 text-2xl text-left pb-4 font-semibold text-sky-800">CONTACT US - COJON LLC.</h1>
            <p className="text-lg text-left text-sky-800 pb-4 pl-4 md:pl-0">REACH OUT FOR A FREE QUOTE</p>
            <LocationBlock />
          </div>
        
        </div>
      </div>
    </MainContainer>
  )
}

export default ContactPage


