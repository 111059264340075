import React, {useState } from 'react'
import MainContainer from '../MainContainer/MainContainer'
import MainContent from '../MainContent/MainContent'
import Moto from '../Moto/Moto'
import SmallHeader from '../SmallHeader/SmallHeader';
import SEO from '../SEO/SEO';

const AboutPage = () => {

  let statement = 'Through a relentless pursuit of quality and a passion for problem-solving, we have successfully executed projects ranging from large-scale commercial developments to intricate engineering solutions. Our client-centric approach means that we work collaboratively with our clients, architects, and stakeholders to ensure every aspect of the project aligns with their vision and requirements.';

  return (
    <MainContainer>
      <SEO 
        title="COJON: Mechanical Engineering & Construction"
        description="Portland Metro & Southwest Washington Commercial & Industrial Contracting"
        name="COJON"
        type="website"
        index
      />
      <SmallHeader />
      <div className='flex flex-row min-h-[500px]'>
        <div className={`w-full pt-8 md:pl-12 md:pr-12`}>
          <div className="flex flex-col md:pb-12">
            <h1 className="pl-4 md:pl-0 text-2xl text-left md:py-4 mb-8 font-semibold text-sky-800">ABOUT COJON</h1>
            <Moto statement={statement} about/>
          </div>
        
        </div>
      </div>
    </MainContainer>
  )
}

export default AboutPage