import React from 'react'

const GeneralInfoSection = () => {
  return (
    <div className="flex flex-col bg-zinc-900 lg:flex-row pb-12">

      <div className="w-full lg:w-1/3 bg-zinc-900 pl-8">
        <h3 className="text-xl pt-12 pb-4  font-bold text-white align-start text-left">ABOUT COJON</h3>
        <p className="text-lg text-left text-white">
         Cojon is the PNW's premier metal contractor. We service our customers along every step of their project. We have experienced experts in house that can conceptualize, 
         fabricate, and install any steel component for their project.
        </p>
        <br/>
        <h3 className="text-xl pb-4 font-bold text-white align-start text-left">VALUES</h3>
        <p className="text-xl text-left text-white">
          Our approach to business is based on honesty, communication, consistency, and reliability. We know every project comes with its own challenges, our customers can count on us to get the job done.
        </p>
      </div>
      <div className="w-full lg:w-1/3 bg-zinc-900 pl-8">
        <h3 className="text-xl pt-12 font-bold text-white align-start text-left pb-4">CONTACT US</h3>
        <div className="flex gap-2 pb-2">
          <span className="text-lg text-left text-white">Email </span>
          <p className="text-xl text-left text-white font-light">
            john.demchek@cojon-mech.com
          </p>
        </div>
        <div className="flex gap-2">
          <span className="text-lg text-left text-white">Phone </span>
          <p className="text-xl text-left text-white font-light">
            <a href="tel:(360) 241-0467">(360) 241-0467</a>
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="text-xl pt-6 font-bold text-white align-start text-left">LICENCES</h3>
          <p className="text-xl text-left text-white font-light">
            OR: CCB# 233852
          </p>
          <p className="text-xl text-left text-white font-light">
            WA: COJONL*816MJ
          </p>
          <p className="text-xl text-left text-white font-light">
            DOT# 3487210
          </p>
        </div>
        <div className="flex flex-col gap-2 py-4">
          <h3 className="text-xl pt-6 font-bold text-white align-start text-left">LOCATION</h3>
          <p className="text-xl text-left text-white font-light">
            1701 W 31st st.
          </p>
          <p className="text-xl text-left text-white font-light">
            Bldg C
          </p>
          <p className="text-xl text-left text-white font-light">
            Vancouver, WA 98660
          </p>
        </div>
      </div>
      <div className="w-full lg:w-1/3 bg-zinc-900 pl-8">
        <h3 className="text-xl pt-12 pb-4 font-bold text-white align-start text-left">FOLLOW UP ON SOCIAL MEDIA</h3>
        <ul>
            <li className="text-md font-semibold text-left text-white pb-4">
              <a href="https://www.facebook.com/Cojonllc?mibextid=ZbWKwL">Facebook</a>
            </li>
            <li className="text-md font-semibold text-left text-white pb-4">
              <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fjohn-demchek-ab6593104%3Ffbclid%3DIwAR10nir_hqsNaTvoL4vZhIrsPDUW2A75aUvAEQ5FfRp5gR7wTOHHFNbeJw8&h=AT3ovYXeuygeyUHtx2wojHI5rQis45m_ORkzTVzYCbm-B7pqg760iuVQxRgumHnoDIpD_WpN2nxCTdbt21BN6wG0kJWONmyf2I4gHKvLqBY8VnXkHrvbL9wl6REpWAZLWONGFg">LinkedIn</a>
            </li>
            <li className="text-md font-semibold text-left text-white pb-4">
              <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Finstagram.com%2Fcojon_llc%3Futm_source%3Dqr%26igshid%3DNGExMmI2YTkyZg%253D%253D%26fbclid%3DIwAR2ufFNprcPF50Lj4owqtp4WsMXeFA3XXf8KuT9s-rK2Kn61ch_T38A1Q90&h=AT0opKBHaqn6O6OM8soZVhXfv0DYgHhUPzMYjbZ9BA7g6b24lKFzKW86um7obU0O10Asv14OY0_cpL3XnziMQxJn4RFY7yR8tyoLAEGJmA4wgyrNjEO2Fz8Eiejl9i_7tPDkEw">Instagram</a>
            </li>
            
        </ul>
      </div>
  </div>
  )
}

export default GeneralInfoSection