import React from 'react'
import { useLocation } from 'react-router-dom';
import MainContainer from '../MainContainer/MainContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import SEO from '../SEO/SEO';

const ProjectPage = () => {
  const { state } = useLocation();
  const project = state[0]


  const navigate = useNavigate();

  
  const goBack = () => {
    navigate(-1)
  }
  return (

    <MainContainer>
      <SEO 
        title="COJON: Mechanical Engineering & Construction"
        description="Portland Metro & Southwest Washington Commercial & Industrial Contracting"
        name="COJON"
        type="website"
        index
      />
   
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/3 bg-neutral-800 relative">
          <div 
            className="absolute left-3 top-3 hover:cursor-pointer "
            onClick={goBack}  
          >
            <FontAwesomeIcon icon={faChevronLeft} className='text-xl text-white' />
          </div>
          <h3 className="text-3xl py-10 text-white">{project.projectName}</h3>
          <p className="leading-8 text-lg text-white font-light p-4">{project.description}</p>
        </div>
        <div className="w-full md:w-2/3">
          <img
            className="object-fit"
            alt="cojon.jpg"
            src={project.backgroundImage}
          />
          {project.images.map((image, index) => (
             <img
                className="object-fit"
                alt="cojon.jpg"
                src={image}
              />
            ))}
          
        </div>
    

      </div>
    </MainContainer>
  )
}

export default ProjectPage