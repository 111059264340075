import React from 'react'

const LocationBlock = () => {
  return (
    <div className="flex flex-col text-left pl-4 md:pl-0">
      {/* <div className='py-2'>
        <p className='text-2xl font-medium text-sky-800'>COJON LLC.</p>
      </div> */}
      <div className='py-2'>
        <p className='text-xl text-sky-800'>Phone</p>
        <span className='text-md text-sky-800'><a href="tel:(360) 241-0467">(360) 241-0467</a></span>
      </div>
      <div className='py-2'>
        <p className='text-xl text-sky-800'>E-Mail</p>
        <span className='text-md text-sky-800'>john.demchek@cojon-mech.com</span>
      </div>
      <div className='py-2'>
        <p className='text-xl text-sky-800'>Location</p>
        <p className='text-md text-sky-800'>1915 Rhododendron Dr.</p>
        <p className='text-md text-sky-800'>Woodland, WA 98674</p>
      </div>
    </div>
  )
}

export default LocationBlock