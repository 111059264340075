import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({title, description, name, type, index}) {
  return (
    <Helmet>

      { /* Standard metadata tags */ }
      <title>{title}</title>
      <meta name='description' content={description} />
      { index && <meta name="robots" content="INDEX,FOLLOW" /> }
      <meta content="width=device-width, initial-scale=1" name="viewport"></meta>
      { /* End standard metadata tags */ }
      { /* Facebook tags */ }
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      { /* End Facebook tags */ }
      { /* Twitter tags */ }
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      { /* End Twitter tags */ }
    </Helmet>
  )
}