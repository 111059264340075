import React from 'react'
import MainContainer from '../MainContainer/MainContainer'
import MainContent from '../MainContent/MainContent'
import img from '../../images/gaston-header.jpg'
import SEO from '../SEO/SEO';

const ServicesPage = () => {
  return (
    <MainContainer>
      <SEO 
        title="COJON: Mechanical Engineering & Construction"
        description="Portland Metro & Southwest Washington Commercial & Industrial Contracting"
        name="COJON"
        type="website"
        index
      />
      <MainContent
        header={'WE CAN HELP WITH THAT'}
        rightHeader={"SERVICES"}
        description={'REACH OUT FOR A FREE QUOTE'}
        links={[
          'PROJECT CONCEPTUALIZATION',
          'DESIGN',
          'BIDDING',
          'FABRICATION DETAILING',
          'STRUCTURAL STEEL',
          'MISC. IRON',
          'STAIRS / HANDRAILS / LADDERS / ETC',
          'PERMITTING',
          'PROJECT COORDINATION'
        ]}
        rightSideVisible={true}
      >

        <img
          className="max-w-full h-auto rounded"
          alt="cojon.jpg"
          src={img}
        />
      </MainContent>
  </MainContainer>
  )
}

export default ServicesPage