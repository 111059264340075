import React from 'react'

const RepItemSmall = ({title, width, backgroundImage}) => {

  return (
    <div
      className="rounded mx-2"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: width > 700 ? `${width - 500}px` : '150px',
        width: width > 700 ? `${width - 500}px` : '100px',
        minHeight: '450px',
        maxHeight: '450px',
        maxWidth: '600px'
      }}
    >
      <div className="w-full h-full bg-black bg-opacity-0 bg-center bg-cover hover:bg-opacity-30 rounded relative hover:cursor-pointer">
        <p className="text-white absolute text-2xl inset-0 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300">
          {title}
        </p>
      </div>
    </div>
  )
}

export default RepItemSmall