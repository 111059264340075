import React from 'react'
import { useState, useEffect } from 'react';
import RepItemSmall from '../RepItemSmall/RepItemSmall';
import RepItemLarge from '../RepItemLarge/RepItemLarge';
import backgroundImage_1 from '../../images/home_1.jpg';
import backgroundImage_2 from '../../images/home_2.jpg';
import backgroundImage_3 from '../../images/home_3.jpg';
const Representation = () => {

  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <> 
      <div className="min-h-[450px]">
        <div className="flex hidden md:block items-center my-auto">
          <div className="flex justify-around my-8 px-4  items-center ">
              <RepItemSmall width={width} title={"WELCOME"}  backgroundImage={backgroundImage_1}/>
              <RepItemSmall width={width} title={"TO" }backgroundImage={backgroundImage_2}/>
              <RepItemSmall width={width} title={"COJON"} backgroundImage={backgroundImage_3}/>
            </div>
        </div>
        <div>
          <div className="flex flex-col justify-around my-8 px-4 pb-12 md:hidden">
              <RepItemLarge width={width} title={"WELCOME"} backgroundImage={backgroundImage_1}/>
              <RepItemLarge width={width} title={"TO"} backgroundImage={backgroundImage_2}/>
              <RepItemLarge width={width} title={"COJON"} backgroundImage={backgroundImage_3}/>
            </div>
        </div>
      </div>
 
     
    </>


  )
}

export default Representation