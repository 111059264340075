import React from 'react'

const Footer = () => {
  return (
    <div className="h-16 flex pl-12 text-xs text-white bg-stone-800 items-center">
      <p> All Rights Reserved © COJON  2023</p>

    </div>
  )
}

export default Footer