import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
const SocialNav = () => {
  return (
    <div className='h-8 flex justify-end items-center mr-8'>
      
      <div className="hover:bg-sky-800 cursor-pointer h-full flex items-end pb-1 rounded-b">
        <a href="https://www.facebook.com/Cojonllc?mibextid=ZbWKwL"><FontAwesomeIcon icon={faFacebookF} className='w-7 text-lg '/></a>
      </div>
      <div className="hover:bg-sky-800 cursor-pointer h-full flex items-end py-1 rounded-b">
        <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fjohn-demchek-ab6593104%3Ffbclid%3DIwAR10nir_hqsNaTvoL4vZhIrsPDUW2A75aUvAEQ5FfRp5gR7wTOHHFNbeJw8&h=AT3ovYXeuygeyUHtx2wojHI5rQis45m_ORkzTVzYCbm-B7pqg760iuVQxRgumHnoDIpD_WpN2nxCTdbt21BN6wG0kJWONmyf2I4gHKvLqBY8VnXkHrvbL9wl6REpWAZLWONGFg"><FontAwesomeIcon icon={faLinkedinIn} className='w-7 text-xl'/></a>
      </div>
      <div className="hover:bg-sky-800 cursor-pointer h-full flex items-end py-1 rounded-b">
        <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Finstagram.com%2Fcojon_llc%3Futm_source%3Dqr%26igshid%3DNGExMmI2YTkyZg%253D%253D%26fbclid%3DIwAR2ufFNprcPF50Lj4owqtp4WsMXeFA3XXf8KuT9s-rK2Kn61ch_T38A1Q90&h=AT0opKBHaqn6O6OM8soZVhXfv0DYgHhUPzMYjbZ9BA7g6b24lKFzKW86um7obU0O10Asv14OY0_cpL3XnziMQxJn4RFY7yR8tyoLAEGJmA4wgyrNjEO2Fz8Eiejl9i_7tPDkEw"><FontAwesomeIcon icon={faInstagram} className='w-7 text-xl' /></a>
      </div>
    </div>
  )
}

export default SocialNav