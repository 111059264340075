import React, {useState} from 'react'
import { Helmet } from 'react-helmet-async'
import Header from '../Header/Header'
import Moto from '../Moto/Moto'
import Representation from '../Representation/Representation'

import MainContainer from '../MainContainer/MainContainer'
import SEO from '../SEO/SEO'



const HomePage = () => {
  return (
    <MainContainer>
      <SEO 
        title="COJON: Mechanical Engineering & Construction"
        description="Portland Metro & Southwest Washington Commercial & Industrial Contracting"
        name="COJON"
        type="website"
        index
      />
      <Header />
      <Moto />
      <Representation />
    </MainContainer>
  )
}

export default HomePage