import React from 'react'
const RepItemLarge = ({width, title, backgroundImage}) => {
  return (
    <div
      className="rounded mx-auto my-2"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: width > 1350 ? `${width - 900}px` : '325px',
        width: '90%',
        maxHeight: '450px',
        
        transition: 'width .5s ease', // Transition property added
      }}
    >
      <div className="w-full h-full bg-black bg-opacity-0 bg-center bg-cover hover:bg-opacity-30 rounded relative hover:cursor-pointer">
        <p className="text-white absolute text-2xl inset-0 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300">
          {title}
        </p>
      </div>
    </div>
  )
}

export default RepItemLarge