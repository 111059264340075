import React, {useEffect, useState} from 'react'
import ImageGallery from '../ImageGallery/ImageGallery'
import backgroundImage from '../../images/header.jpg';
import backgroundImage_1 from '../../images/clatsop-county-jail-header.jpg'
import backgroundImage_2 from '../../images/gaston-header.jpg'
import backgroundImage_3 from '../../images/sunrise-water-authority-header.jpg'
import backgroundImage_4 from '../../images/vita-header.jpg'
import backgroundImage_5 from '../../images/temp-kalama.jpg'
import backgroundImage_6 from '../../images/harold-oliver-header.jpg'
import backgroundImage_7 from '../../images/mainstreet-header.jpg'
import backgroundImage_8 from '../../images/Daimler-header.jpg'
import gaston_1 from '../../images/gaston_0.jpg'
import gaston_2 from '../../images/gaston_1.jpg'
import gaston_3 from '../../images/gaston_2.jpg'
import county_0 from '../../images/countyJail_0.jpg'
import county_1 from '../../images/countyJail_1.jpg'
import county_2 from '../../images/countyJail_2.jpg'
import kalama_0 from '../../images/kalama-header.jpg'
import kalama_1 from '../../images/Kalama_1.jpg' 
import kalama_2 from '../../images/Kalama_2.jpg'
import vita_0 from '../../images/vita_0.jpg'
import vita_1 from '../../images/vita_1.jpg'
import sunrise_0 from '../../images/sunrise-header.jpg'
import sunrise_1 from '../../images/home_1.jpg'
import harold_0 from '../../images/harold_0.jpg'
import harold_1 from '../../images/harold_1.jpg'
import main_0 from '../../images/main_0.jpg'
import main_1 from '../../images/main_1.jpg'
import main_2 from '../../images/main_4.jpg'
import main_3 from '../../images/main_5.jpg'
import main_4 from '../../images/main_6.jpg'
import dam_0 from '../../images/dam_0.jpg'
import dam_1 from '../../images/dam_1.jpg'
import dam_2 from '../../images/dam_2.jpg'


import { Outlet, useNavigate } from 'react-router-dom';
import SEO from '../SEO/SEO';

import Nav from '../Nav/Nav'
import GeneralInfoSection from '../GeneralInfoSection/GeneralInfoSection'
import NavPanel from '../NavPanel/NavPanel'
import Footer from '../Footer/Footer';
import ProjectModal from '../ProjectModal/ProjectModal'

const ProjectsPage = () => {

  const [open, setOpen] = useState(false)
  const [filter, setFilter] = useState('ALL PROJECTS');
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [project, setProject] = useState('')
  const navigate = useNavigate();

  
  const navigateToProject = (projectName) => {
    let project = images.filter((image) => image.projectName == projectName)
    navigate(`/projects/${projectName}`,  { state: project })
  }

   const handleStateUpdate = (projectName) => {
    setProject(projectName)
    navigateToProject(projectName)
   }

  const [titles, setTitles] = useState([
    'ALL PROJECTS',
    'CIVIC & COMMUNITY',
    //'HEALTH CARE',
    //'HIGHER EDUCATION',
    //'HOSPITALITY & RETAIL',
    'INDUSTRAIL',
    'K-12 SCHOOLS',
    'OFFICE & COMMERCIAL',
    //'RELIGIOUS'
  ])

  const [images, setImages] = useState([
    {
      title: 'CIVIC & COMMUNITY',
      backgroundImage: backgroundImage_1,
      images: [county_0, county_1, county_2],
      projectName: 'Clatsop County Jail',
      description: 'This was new construction of a jail that included 5 staircases as well as hundreds of feet of guardrail. We also fabricated an installed new roof access ships ladders.'
    },
    {
      title: 'CIVIC & COMMUNITY',
      backgroundImage: backgroundImage_2,
      images: [gaston_1, gaston_2, gaston_3],
      projectName: 'Gaston Fire Department',
      description: 'Added moment frames as well as an entry canopy to an existing building.'
    },
    {
      title: 'CIVIC & COMMUNITY',
      backgroundImage: backgroundImage_3,
      images: [sunrise_0, sunrise_1],
      projectName: 'Sunrise Water Authority',
      description: 'New construction full Structural and Misc steel package. This project includes a CLT shop and administration building. This is a prevailing wage project which included over 2000 man hours. Full 3D modeling and drawing package was done in house as well as fabrication and installation.'
    },
    {
      title: 'CIVIC & COMMUNITY',
      backgroundImage: backgroundImage_4,
      images: [vita_0, vita_1],
      projectName: 'VITA covered play structure',
      description: 'This project consisted of erecting a pre engineered metal building. We also installed the T&G roof decking as well as the metal roofing and gutters '
    },
    {
      title: 'K-12 SCHOOLS',
      backgroundImage: backgroundImage_5,
      images: [kalama_0, kalama_1, kalama_2],
      projectName: 'Kalama Elementary School',
      description: 'We Fabricated guard rail and stainless handrailing for 4 stair cases as well as trash enclosure gates, mechanical screens, and roof access ladders.'
    },
    {
      title: 'K-12 SCHOOLS',
      backgroundImage: backgroundImage_6,
      images: [harold_1,harold_0],
      projectName: 'Harold Oliver Middle School',
      description: 'This project consisted of several seismic upgrades as well as the fabrication and installation of a new trash enclosure. We also supplied the covered entry canopy.'
    },
    {
      title: 'INDUSTRAIL',
      backgroundImage: backgroundImage_8,
      images: [dam_0, dam_1, dam_2],
      projectName: 'Daimler Battery Crane',
      description: 'Demolition of existing overhead bridge crane and installation of new 110ft long runway and install of new overhead bridge cranes.'
    },
    {
      title: 'OFFICE & COMMERCIAL',
      backgroundImage: backgroundImage_7,
      images: [main_2, main_3, main_4, main_0, main_1],
      projectName: 'Main St Restaurants',
      description: 'Full Structural and Misc Steel Package to renovate an existing bank and turn it into 5 restaurants. Seismic and structural upgrades included new steel columns and a steel deck frame on the roof for an outdoor bar. One new staircase, over 300 ft of stainless cable railing, and framing for a new elevator.'
    },
    // Add more image URLs as needed
  ]);
  
  
  return (
    <div className="w-full mt-32 bg-zinc-800">
      <SEO 
        title="COJON: Mechanical Engineering & Construction"
        description="Portland Metro & Southwest Washington Commercial & Industrial Contracting"
        name="COJON"
        type="website"
        index
      />
      
      {open && <NavPanel setOpen={setOpen}/>}
      <Nav setOpen={setOpen}/>
      {/* main content */}
      {projectModalOpen && <ProjectModal project={project} closeProjectModal={() => setProjectModalOpen(prev => !prev)}/>}
      <h2 className="text-left text-2xl px-4 pt-8 text-zinc-300 font-semibold">{filter}</h2>
        
      <div className='flex flex-wrap p-4 '>
        {titles.map((title, index) => {
          return (
            <div className="py-2" key={index}>
              {index > 0 && <span className="px-2 text-zinc-600">/</span>}
              { filter == title ?
                <a className="text-zinc-300 hover:cursor-pointer"
                  onClick={() => {
                    setFilter(title)
                  }}
                >{title}</a> :
                <a className="text-zinc-600 hover:cursor-pointer"
                  onClick={() => {
                    setFilter(title)
                  }}
                >{title}</a>
              }
        
            </div>

          )
        })}

      </div>
      <ImageGallery images={filter !== "ALL PROJECTS" ? images.filter((image) => image.title == filter) : images} openModal={handleStateUpdate} />
 
      <GeneralInfoSection />
      <Footer />
    
    </div>
    
  )
}

export default ProjectsPage