import React, {useState} from 'react'
import Footer from '../Footer/Footer'
import GeneralInfoSection from '../GeneralInfoSection/GeneralInfoSection'
import NavPanel from '../NavPanel/NavPanel'
import Nav from '../Nav/Nav'
const MainContainer = ({children}) => {

  const [open, setOpen] = useState(false)

  return (
    <div className='h-full mt-32'>
      {open && <NavPanel setOpen={setOpen}/>}
      <Nav setOpen={setOpen}/>
      {children}
      <GeneralInfoSection />
      <Footer />
  </div>
  )
}

export default MainContainer