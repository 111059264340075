import React from 'react'
import backgroundImage from '../../images/sunrise-header.jpg';
import { useState, useEffect } from 'react';
const Header = () => {

  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <div className="flex h-full">
      <div
        className="w-full"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: width <= 678 ? `${width-100}px` : '650px',
        }}
      >
      </div>
    </div>
  );
}

export default Header