import React from 'react'

const ProjectModal = ({projectName, closeProjectModal}) => {
  return (
    <div className="flex w-full h-full bg-zinc-800 bg-opacity-95 p-4">
      <div className="flex justify-between w-full">
        <p>ProjectModal</p>
        <div className="text-lg hover:cursor-pointer p-4"
          onClick={closeProjectModal}
        >x</div>
      </div>
    </div>
  )
}

export default ProjectModal