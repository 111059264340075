import logo from './logo.svg';
import './App.css';
import { useState } from 'react';

import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import HomePage from './components/HomePage/HomePage.jsx';
import AboutPage from './components/AboutPage/AboutPage';
import PeoplePage from './components/PeoplePage/PeoplePage';
import ServicesPage from './components/ServicesPage/ServicesPage';
import ProjectsPage from './components/ProjectsPage/ProjectsPage';
import ProjectPage from './components/ProjectPage/ProjectPage';
import NewsPage from './components/NewsPage/NewsPage';
import ContactPage from './components/ContactPage/ContactPage';
import CareersPage from './components/CareersPage/CareersPage';
import { HelmetProvider } from 'react-helmet-async';
import AutoScroll from './components/AutoScroll/AutoScroll';
function App() {
  //const isOpen = useSelector((state) => {return state.globalState.isOpen})
  //const isLoggedIn = useSelector((state)=>{ return state.currentUser.isLoggedIn}) // this is the token

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <div className="App bg-zinc-200" style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <BrowserRouter>
          <Routes>
            <Route exact index path="/" element={<HomePage /> } />  
            <Route exact path='/about' element={<AboutPage />} />
            <Route exact path='/people' element={<PeoplePage />} />
            <Route exact path='/services' element={<ServicesPage />} />
            <Route exact path='/projects' element={<ProjectsPage />} />
            <Route path='/projects/:projectName' element={<ProjectPage />} /> {/*A nested route!*/}
            <Route exact path='/careers' element={<CareersPage />} />
            <Route exact path='/news' element={<NewsPage/>} />
            <Route exact path='/contact' element={<ContactPage/>} />
          </Routes>
          <AutoScroll />
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;

