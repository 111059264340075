import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';


const NavPanel = ({setOpen}) => {
  return (
    
    <div className="h-screen fixed w-full top-0 right-0 z-20 sm:w-1/3 max-w-[250px] flex flex-col p-4  bg-white">
      <div className="flex justify-end py-6">
        <FontAwesomeIcon icon={faX} className='w-4 text-2xl text-sky-800 hover:cursor-pointer' onClick={() => {setOpen((prev) => !prev)}} />
      </div>
    
      <div className="py-4 flex justify-start pl-8">
        <Link className="text-sky-800 text-lg" to="/">HOME</Link>
      </div>
      <div className="py-4 flex justify-start pl-8">
        <Link className="text-sky-800 text-lg" to="/about">ABOUT</Link>
      </div>
      {/* <div className="py-4 flex justify-start pl-8">
        <Link className="text-sky-800 text-lg" to="/people">PEOPLE</Link>
      </div> */}
      <div className="py-4 flex justify-start pl-8">
        <Link className="text-sky-800 text-lg" to="/services">SERVICES</Link>
      </div>
      <div className="py-4 flex justify-start pl-8">
        <Link className="text-sky-800 text-lg" to="/projects">PROJECTS</Link>
      </div>
      {/* <div className="py-4 flex justify-start pl-8">
        <Link className="text-sky-800 text-lg" to="/news">NEWS</Link>
      </div>
      <div className="py-4 flex justify-start pl-8">
        <Link className="text-sky-800 text-lg" to="/careers">CAREERS</Link>
      </div> */}
      <div className="py-4 flex justify-start pl-8">
        <Link className="text-sky-800 text-lg" to="/contact">CONTACT</Link>
      </div>
    </div>
  )
}

export default NavPanel